import anniversary from "@languages/ja/anniversary.json";
import comics from "@languages/ja/comics.json";
import common from "@languages/ja/common.json";
import download from "@languages/ja/download.json";
import error from "@languages/ja/error404.json";
import footerSns from "@languages/ja/footer-sns.json";
import footer from "@languages/ja/footer.json";
import gallery from "@languages/ja/gallery.json";
import hotitems from "@languages/ja/hotitems.json";
import illust from "@languages/ja/illust.json";
import index from "@languages/ja/index.json";
import labo from "@languages/ja/labo.json";
import linestickers from "@languages/ja/linestickers.json";
import music from "@languages/ja/music.json";
import news from "@languages/ja/news.json";
import privacy from "@languages/ja/privacy.json";
import vocaloid from "@languages/ja/vocaloid.json";

const data = {
	...index,
	...news,
	...comics,
	...gallery,
	...vocaloid,
	...download,
	...footerSns,
	...footer,
	...error,
	...labo,
	...music,
	...hotitems,
	...illust,
	...common,
	...anniversary,
	...linestickers,
	...privacy,
};
export default data;
