import Link from "@components/Link";
import {} from "@hooks/useMediaQuery";
import { useMenuLink } from "@hooks/useURL";
import {} from "gatsby";

import * as css from "@css/components/blocks/global/HeaderSp.module.styl";
import LogoSvg from "@images/Global__logo__miraicomachi.svg";

export default function GlobalHeaderSp() {
	const menuLinks = useMenuLink();

	return (
		<header className={css.container}>
			<div className={css.inner}>
				<Link className={css.logo} to="/">
					<LogoSvg />
				</Link>

				<div className={css.lange}>{/* <LanguageButton /> */}</div>
			</div>
		</header>
	);
}
