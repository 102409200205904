import Header from "@components/blocks/global/Header";
import { useEventListener } from "@hooks/useEventListener";
import { useGsapPlugins } from "@hooks/useGsapPlugins";
// import ContentModal from "@components/ContentModalSlider"
import useLanguage from "@hooks/useLanguage";
import { duration, useTransition } from "@hooks/useTransitionStatus";
import loadable from "@loadable/component";
import browser from "browser-detect";
import { AnimatePresence, motion } from "framer-motion";
import LocomotiveScroll from "locomotive-scroll";
import React from "react";
import "@css/style.styl";
import Link from "@components/Link";
import Loading from "@components/Loading";
import { isPopState, isWebGlState, langState, scrollState } from "@status";
import CookieConsent from "react-cookie-consent";
import { useRecoilState, useRecoilValue } from "recoil";

const _duration = 0.6;
const delay = duration;
const ease = { ease: [0.75, 0.0, 0.25, 1.0] };
const ThreeJs = loadable(() => import("@three/Main.js"));

const mainVars = {
	initial: {
		// position: 'relative',
		opacity: 0,
	},
	enter: {
		transition: {
			duration: _duration,
			delay: delay,
			// when: 'beforeChildren',
		},
		opacity: 1,
	},
	exit: {
		transition: { duration: _duration },
		opacity: 0,
	},
};

const coverVars = (isFirst) => {
	return {
		initial: {
			zIndex: 10,
			background: "#DF4661",
			position: "fixed",
			width: "100%",
			height: "100%",
			top: 0,
			left: 0,
			x: `${isFirst ? "100%" : "0%"}`, //初期表示を早くするため
		},
		enter: {
			transition: {
				duration: _duration,
				delay: delay,
				when: "afterChildren",
				...ease,
			},
			x: "100%",
		},
		exit: {
			transition: {
				duration: _duration,
				// tims: [duration/2,duration/2],
				...ease,
			},
			x: ["-100%", "0%"],
		},
	};
};

// Particleでトランジションを管理しているよ！
export default function TransitionLayout({ children, location, ...props }) {
	// const [_locomotiveScrollState, setLocomotiveScrollState] = useRecoilState(locomotiveScrollState)
	const _langState = useRecoilValue(langState);
	const [_scrollState, setScrollState] = useRecoilState(scrollState);
	const _isPopState = useRecoilValue(isPopState);
	const _isWebGlState = useRecoilValue(isWebGlState);
	// const [browsed, setBrowsed] = React.useState()
	const wrapRef = React.useRef();
	const containerRef = React.useRef();
	const { customEase01 } = useGsapPlugins();
	const transition = useTransition({ isStayEnd: true });
	const [resize, setResize] = React.useState(0);
	const locomotiveScroll = React.useRef();
	useLanguage();

	useEventListener("popstate", () => {
		if (_isPopState) {
			transition.start();
		}
	});

	useEventListener("resize", () => {
		setResize(resize + 1);
	});

	const browsed = React.useRef(browser());
	let isWebGl = true;
	if (browsed.current.name === "ie" || !_isWebGlState) {
		isWebGl = false;
	}

	React.useEffect(() => {
		window._scroll = {
			currentScrollTop: 0,
			currentScrollRaito: 0,
			preScrollTop: 0,
			currentScrollTarget: null,
		};

		if (!isWebGl) {
			document.body.style.height = "auto";
			document.body.style.overflow = "auto";
			return;
		}
		if (typeof window === "undefined") {
			return;
		}

		wrapRef.current.classList.add("wrap-scroll");
		containerRef.current.classList.add("root-container-scroll");

		if (locomotiveScroll.current) {
			locomotiveScroll.current.update();
			return;
		}

		locomotiveScroll.current = new LocomotiveScroll({
			el: containerRef.current,
			smooth: true,
			lerp: 0.09,
			multiplier: 1.1,
			touchMultiplier: 1.9,
			smartphone: {
				smooth: true,
			},
			tablet: {
				smooth: true,
			},
		});
		locomotiveScroll.current.update();

		locomotiveScroll.current.myHide = () => {
			const doms = document.getElementsByClassName("c-scrollbar");
			for (const dom of doms) {
				dom.classList.add("hide-scrollbar");
			}
		};

		locomotiveScroll.current.myShow = () => {
			const doms = document.getElementsByClassName("c-scrollbar");
			for (const dom of doms) {
				dom.classList.remove("hide-scrollbar");
			}
		};

		// appStore.locomotiveScroll = locomotiveScroll;
		window.locomotiveScroll = locomotiveScroll.current;

		return () => {
			if (locomotiveScroll) locomotiveScroll.current.destroy();
		};
	}, []);

	React.useEffect(() => {
		if (locomotiveScroll.current) {
			locomotiveScroll.current.update();
		}
	}, [resize]);

	React.useEffect(() => {
		if (locomotiveScroll.current) {
			setTimeout(() => {
				locomotiveScroll.current.update();
			}, 100);
		}
	}, [_langState]);

	return (
		<div id="wrap" ref={wrapRef}>
			<Consent />
			<Header />
			<Loading />
			{isWebGl && <ThreeJs />}
			<div id="root-container" className="root-container" ref={containerRef}>
				<AnimatePresence
					onExitComplete={() => {
						setTimeout(() => {
							// containerRef.current.scrollTo(0,0)
							// appStore.transitionMiddle()
							transition.stayStart();
							window._scroll.currentScrollTop = 0;
							window._scroll.currentScrollRaito = 0;
							console.log("onExitComplete--1=>", window._scroll);
						}, duration);
					}}
				>
					<motion.div
						key={location.pathname}
						variants={mainVars}
						initial="initial"
						animate="enter"
						exit="exit"
					>
						{children}
					</motion.div>
				</AnimatePresence>
			</div>
		</div>
	);
}

const Consent = () => (
	<CookieConsent
		className={"cookie"}
		debug={process.env.NODE_ENV === "development"} // debug用
		buttonText="OK"
		// cookieName="myAwesomeCookieName"
		// disableStyles={true}
		style={{ background: "#DF4661", fontSize: 14, lineHeight: "1.6em" }}
		contentStyle={{ padding: 0, margin: 0, flex: "" }}
		buttonStyle={{ borderRadius: 100, fontSize: 14, margin: 0, padding: 0 }}
		// expires={150}
	>
		本ウェブサイトは、利便性及び品質の維持・向上を目的に、クッキーを使用しております。なお、クッキーの利用について、詳しくは
		<Link style={{ display: "hidden" }} to={"/privacy-policy"}>
			こちら
		</Link>
		をご参照下さい。
	</CookieConsent>
);

function remakeChildren(children, newProps) {
	return React.Children.map(children, (child) => {
		// console.info(typeof child, child)

		switch (typeof child) {
			case "string":
				// 子要素がテキストノードだった場合はそのまま return
				return child;

			case "object":
				// React要素だった場合は newProps を渡す
				return React.cloneElement(child, newProps);

			default:
				// それ以外の場合はとりあえず null 返しとく
				return <div>error</div>;
		}
	});
}
