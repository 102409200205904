import { atom } from 'recoil'
import { WEBGL } from 'three/examples/jsm/WebGL.js'


export const scrollState = atom({
    key: 'scrollState',
    default: {
        preScrollTop: 0,
        currentScrollTarget: 0,
        currentScrollTop:0,
        currentScrollRaito:0
    }
});

export const isPopState = atom({
    key: 'isPopState',
    default: true
});

export const langState = atom({
    key: 'LangState',
    default: "ja"
});


export const locomotiveScrollState = atom({
    key: 'locomotiveScrollState',
    default: null
});

export const isWebGlState = atom({
    key: 'isWebGlState',
    default: WEBGL.isWebGLAvailable()
});

export const loadState = atom({
    key: 'loadState',
    default: {
        	isLoadedModelData: false,
            isLoaded: false,
            isLoadedAnimated: false,
            isLoadedParticleAnimated: false,
    }
});

export const transitionCurrentState = atom({
    key: 'transitionCurrentState',
    default: "none"
});

export const ModalMenuState = atom({
    key: 'ModalMenuState',
    default: {
        isOpenModalMenu: false,
        isNowModalAnimation: false,
        isModalMenuTransition: false,
        isModalMenuSmoothScroll: false,
    }
});

export const ModalContentState = atom({
    key: 'ModalContentState',
    default: {
        data:null,
        dataList: null,
        dataListCurrentId: null,
        isContentModal: false,
        isContentModalDetail: false,
    }
});
