import { ModalContentState, transitionCurrentState } from "@status";
import React from "react";
import { useRecoilState } from "recoil";
// current : none start middle end
const duration = 1.6;
const Status = React.createContext({
	current: "none",
	startActions: [() => {}],
	middleActions: [() => {}],
	endActions: [() => {}],
});

const useTransitionStatus = () => {
	const status = React.useContext(Status);
	return status;
};

const useTransition = ({ isStayEnd }) => {
	const [_transitionCurrentState, setTransitionCurrentState] = useRecoilState(
		transitionCurrentState,
	);
	const [_modalContentState, setModalContentState] =
		useRecoilState(ModalContentState);
	const [_modalMenuState, setModalMenuState] =
		useRecoilState(ModalContentState);
	const intervalRef = React.useRef(null);
	const [isStatyStart, setIsStayStart] = React.useState(false);
	const d = duration * 1000;

	const start = () => {
		//start
		console.log("===========start");
		setModalContentState({
			..._modalContentState,
			isContentModal: false,
			dataList: [],
		});
		setTransitionCurrentState("start");

		setTimeout(() => {
			console.log("===========middle");
			setTransitionCurrentState("middle");
			setModalMenuState({
				..._modalMenuState,
				isModalMenuTransition: false,
			});
		}, d * 1);

		if (!isStayEnd) {
			setTimeout(() => {
				console.log("===========end");
				setTransitionCurrentState("end");
			}, d * 2);
		} else {
			setTimeout(() => {
				console.log("===========stay");
				setTransitionCurrentState("stay");
			}, d * 2);
		}
	};

	React.useEffect(() => {
		console.log("==== ch effect");
		clearInterval(intervalRef.current);
		if (!isStatyStart) {
			// setIsStayStart(true)
			intervalRef.current = setInterval(async () => {
				console.log("============ check ts", _transitionCurrentState);
				if (_transitionCurrentState === "stay") {
					console.log("===========end");
					setTransitionCurrentState("end");
					clearInterval(intervalRef.current);
					setTimeout(() => {
						setTransitionCurrentState("none");
					}, d / 2);
				} else {
					clearInterval(intervalRef.current);
					console.log("============ ch over", _transitionCurrentState);
				}
			}, 300);
		} else {
		}
	}, [_transitionCurrentState]);

	const stayStart = () => {
		if (!isStatyStart) {
			// setIsStayStart(true)
		}
	};

	return {
		status: React.useContext(Status),
		duration: duration,
		start,
		stayStart,
	};
};

export { useTransition, duration, Status };
