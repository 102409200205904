import i18n from "i18next";
import React, { useMemo } from "react";
import { initReactI18next } from "react-i18next";
import { useRecoilValue } from "recoil"
import { langState } from "@status"

//commone
import En from "@languages/import-en";
import Ja from "@languages/import-ja";

const LanguageContext = React.createContext();

const useLanguageContext = () => {
	const context = React.useContext(LanguageContext);
	return context;
};

const useLanguage = () => {
	const currentLang = useRecoilValue(langState);

	React.useEffect(() => {});
	const resources = {
		en: {
			translation: { ...En },
		},
		ja: {
			translation: { ...Ja },
		},
	};
	const lang = currentLang;
	console.log("ja=>", resources);

	return useMemo(
		() =>
			i18n.use(initReactI18next).init({
				lng: lang,
				fallbackLng: lang,
				debug: true,

				interpolation: {
					escapeValue: false,
				},

				react: {
					wait: true,
				},
				resources: resources,
			}),
		[],
	);
};

export default useLanguage;
