// extracted by mini-css-extract-plugin
export var bg = "Header_v2-module--bg--40769";
export var container = "Header_v2-module--container--b5537";
export var inner = "Header_v2-module--inner--60781";
export var item = "Header_v2-module--item--95233";
export var item_lange = "Header_v2-module--item_lange--52466";
export var item_logo = "Header_v2-module--item_logo--05b7c";
export var list = "Header_v2-module--list--5aebe";
export var menu = "Header_v2-module--menu--aa71a";
export var nav_left = "Header_v2-module--nav_left--b4694";
export var nav_right = "Header_v2-module--nav_right--46d78";
export var pc = "Header_v2-module--pc--4e902";
export var sp = "Header_v2-module--sp--de321";
export var sp2 = "Header_v2-module--sp2--e9ca4";