import wrapWithLayout from "./wrap-with-layout";
import wrapWithProvider from "./wrap-with-provider";

import "prismjs/themes/prism-tomorrow.css";
import "prismjs/plugins/command-line/prism-command-line.css";

//mobx
export const wrapRootElement = wrapWithProvider;
export const wrapPageElement = wrapWithLayout;

// ES5 way
// exports.onClientEntry = () => {
// ES6 way
export const onClientEntry = () => {
	// IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
	if (typeof window.IntersectionObserver === "undefined") {
		require("intersection-observer");
		console.log("# IntersectionObserver is polyfilled!");
	}
};

//transition Layout

const transitionDelay = 1200;

export const shouldUpdateScroll = ({
	routerProps: { location },
	getSavedScrollPosition,
}) => {
	//#用
	if (location.hash !== "") {
		return false;
	}

	//overflow: hiddenしているため
	const rootContainer = document.getElementById("root-container");

	if (location.action === "PUSH") {
		window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
		if (rootContainer) {
			window.setTimeout(() => rootContainer.scrollTo(0, 0), transitionDelay);
		}
	} else {
		const savedPosition = getSavedScrollPosition(location);
		window.setTimeout(
			() => window.scrollTo(...(savedPosition || [0, 0])),
			transitionDelay,
		);

		if (rootContainer) {
			window.setTimeout(
				() => rootContainer.scrollTo(0, 0) || [0, 0],
				transitionDelay,
			);
		}
	}
	return false;
};

//ブラウザバックのイベント取得できる
// export const onInitialClientRender = () => {
//     window.addEventListener('popstate', () =>
//         console.log('===============================',window.location.href)
//         // window.location.href = window.location.href
//     )
// }
