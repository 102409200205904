// extracted by mini-css-extract-plugin
export var child = "ModalMenu-module--child--e2b1e";
export var container = "ModalMenu-module--container--1f2ab";
export var cover = "ModalMenu-module--cover--3bdb3";
export var inner = "ModalMenu-module--inner--10306";
export var item = "ModalMenu-module--item--ebb7c";
export var item2 = "ModalMenu-module--item2--6d456";
export var label = "ModalMenu-module--label--19b2c";
export var lange = "ModalMenu-module--lange--d5e10";
export var logo = "ModalMenu-module--logo--9124b";
export var menu = "ModalMenu-module--menu--dc2c6";
export var menuButton = "ModalMenu-module--menuButton--e4659";
export var sns = "ModalMenu-module--sns--ccea2";