// import scrollTo from 'gatsby-plugin-smoothscroll';
// import { animateScroll as scroll } from 'react-scroll'
import { SmoothScroll } from "@hooks/useSmoothScroll";
import { globalHistory } from "@reach/router";
import {
	ModalContentState,
	ModalMenuState,
	loadState,
	transitionCurrentState,
} from "@status";
import Browser from "browser-detect";
import { navigate } from "gatsby";
import React from "react";
import { useRecoilState } from "recoil";

import { useTransition } from "@hooks/useTransitionStatus";

export default function Link({
	children,
	onClick = () => {},
	to = null,
	scrollTarget = null,
	delay = 0,
	target,
	disableMode = false,
	isHtml = false,
	...props
}) {
	const [_loadState, setLoadState] = useRecoilState(loadState);
	const [_modalContentState, setModalContentState] =
		useRecoilState(ModalContentState);
	const [_modalMenuState, setModalMenuState] = useRecoilState(ModalMenuState);
	const [_transitionCurrentState, setTransitionCurrentState] = useRecoilState(
		transitionCurrentState,
	);
	const transition = useTransition({ isStayEnd: true });
	const browser = React.useRef(Browser());

	const { className, key } = props;

	let offset = -100;
	if (browser.current.mobile) {
		offset = -64;
	}

	const handleClick = (e) => {
		e.stopPropagation();
		e.preventDefault();
		if (_transitionCurrentState !== "none") {
			return;
		}

		const pathname = globalHistory.location
			? globalHistory.location.pathname
			: null;

		if (pathname === to) {
			//遷移させない
			if (scrollTarget) {
				// navigate(`${to}${scrollTarget}`)
				if (_modalMenuState.isOpenModalMenu) {
					setModalMenuState({
						..._modalMenuState,
						isNowModalAnimation: true,
						isOpenModalMenu: false,
					});
				}

				if (window.locomotiveScroll) {
					window.locomotiveScroll.scrollTo(scrollTarget, {
						offset: offset,
						duration: 600,
					});
				} else {
					SmoothScroll(scrollTarget, offset);
				}
			}
		} else {
			//遷移する
			if (_modalMenuState.isOpenModalMenu) {
				setModalMenuState({
					..._modalMenuState,
					isModalMenuTransition: true,
					isOpenModalMenu: false,
				});
			}
			transition.start();

			if (onClick) {
				onClick(e);
			}
			if (!scrollTarget) {
				setTimeout(() => {
					navigate(to);
				}, delay);
			} else {
				setTimeout(() => {
					navigate(`${to}`);
					setTimeout(() => {
						if (window.locomotiveScroll) {
							window.locomotiveScroll.scrollTo(scrollTarget, {
								offset: offset,
								duration: 600,
							});
						} else {
							SmoothScroll(scrollTarget);
						}
					}, 3000);
				}, delay);
			}
		}
	};

	// const [isDisable, setIsDisable] = React.useState(false)

	// React.useEffect(()=>{
	// 	let pathname = globalHistory.location ? globalHistory.location.pathname : null
	// 	if( disableMode === false ){
	// 		setIsDisable(true)
	// 	}
	// 	if( pathname === to || pathname === `/recruit${to}` || pathname === `/recruit${to}/` ){
	// 		setIsDisable(true)
	// 	} else {
	// 		setIsDisable(false)
	// 	}
	// })

	return (
		<React.Fragment>
			{to && !to.match(/http/) ? (
				!isHtml ? (
					<a
						key={key || null}
						href={to}
						className={`${className ? className : ``}`}
						onClick={(e) => handleClick(e)}
					>
						{children}
					</a>
				) : (
					<a
						key={key || null}
						href={to}
						className={`${className ? className : ``}`}
						onClick={(e) => handleClick(e)}
						dangerouslySetInnerHTML={{ __html: children }}
					/>
				)
			) : !isHtml ? (
				<a
					className={`${className ? className : ``}`}
					href={to}
					target={target}
					rel="noopener noreferrer"
				>
					{children}
				</a>
			) : (
				<a
					className={`${className ? className : ``}`}
					href={to}
					target={target}
					rel="noopener noreferrer"
					dangerouslySetInnerHTML={{ __html: children }}
				/>
			)}
		</React.Fragment>
	);
}
