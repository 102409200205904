import { useTranslation } from "react-i18next";

// Hook

const useMenuLink = () => {
	return [
		// {
		//     label: 'About',
		//     to: '/',
		//     target: '#about'
		// },
		{
			label: "NEWS",
			to: "/news/",
			target: null,
		},
		{
			label: "OFFICIAL WORKS",
			to: "/gallery/",
			child: [
				{
					label: "ILLUST",
					to: "/illust/",
				},
				{
					label: "COMICS",
					to: "/comics/",
				},
				{
					label: "SONGS",
					to: "/songs/",
				},
				{
					label: "LABO",
					to: "/labo/",
				},
			],
		},
		{
			label: "GOODS",
			to: "/goods/",
			child: [
				{
					label: "VOCALOID™",
					to: "/vocaloid/",
				},
				{
					label: "LINE STICKERS",
					to: "/linestickers/",
				},
				{
					label: "HOT ITEMS",
					to: "/hotitems/",
				},
			],
		},
		// {
		// 	label: "SHOP",
		// 	to: "https://mangahack.myshopify.com/?sc_cid=txt_tw_20211021",
		// 	blank: true,
		// 	target: null,
		// },
		{
			label: "DOWNLOAD",
			to: "/download/",
			target: null,
		},
		{
			label: "CONTACT",
			to: "/",
			target: "#contact",
		},
	];
};

const useFooterLink = () => {
	const { t } = useTranslation();
	return [
		{
			label: t("footer__privacy"),
			to: "/privacy-policy/",
		},
	];
};

const useURL = () => {
	return {
		twitter: "https://twitter.com/miraikomachi_pr",
		youtube: "https://www.youtube.com/channel/UCxxn_H4NADHRHKS8qMsWjDA/",

		bandainamuco: "https://www.bandainamco-mirai.com",
		privacy: "https://www.bandainamco-mirai.com/privacy/",
		store: "https://www.vocaloid.com/products/show/v4l_komachi",

		modelUnityZip:
			"https://github.com/Miraikomachi/MiraikomachiUnity/archive/master.zip",
		modelUnityRepo: "https://github.com/Miraikomachi/MiraikomachiUnity",
		modelUnityZip201904:
			"https://github.com/Miraikomachi/MiraikomachiUnityUTS/archive/main.zip",
		modelUnityRepo201904:
			"https://github.com/Miraikomachi/MiraikomachiUnityUTS",
		modelVrmZip:
			"https://github.com/Miraikomachi/MiraikomachiVRM/archive/master.zip",
		modelVrmRepo: "https://github.com/Miraikomachi/MiraikomachiVRM",
		modelMmdZip:
			"https://github.com/Miraikomachi/MiraikomachiPMX/archive/master.zip",
		modelMmdRepo: "https://github.com/Miraikomachi/MiraikomachiPMX",
		modelBlenderZip:
			"https://github.com/Miraikomachi/MiraikomachiForBlender/archive/refs/heads/main.zip",
		modelBlenderRepo: "https://github.com/Miraikomachi/MiraikomachiForBlender",
		voiceZip:
			"https://github.com/Miraikomachi/AIVoiceSamples/archive/refs/heads/main.zip",
		voiceRepo: "https://github.com/Miraikomachi/AIVoiceSamples",

		shop: "https://mangahack.myshopify.com/?sc_cid=txt_tw_20211021",
	};
};

export { useMenuLink, useFooterLink, useURL };
