// import { Provider} from 'mobx-react'

// eslint-disable-next-line react/display-name,react/prop-types
// export default ({ element }) => <Provider store={Store}>{element}</Provider>;
import { RecoilRoot } from 'recoil'
export default ({ element }) => {
    return <RecoilRoot>
            {element}
    </RecoilRoot>
}
