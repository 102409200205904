import * as css from "@css/components/LoadingAnimation.module.styl";

const LoadableFallback = () => {
	return (
		<div className={css.container}>
			<div className={css.inner}>
				<div className={css.s1} />
				<div className={css.s2} />
				<div className={css.s3} />
			</div>
		</div>
	);
};

export default LoadableFallback;
