import * as css from "@css/components/LanguageButton.module.styl";
import { langState } from "@status";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import LangEnSVG from "@images/GlobalHeader__btn__lange-en.svg";
import LangJpSVG from "@images/GlobalHeader__btn__lange-jp.svg";

const LanguageButton = () => {
	const [currentLang, setCurrentLang] = useRecoilState(langState);
	const { i18n } = useTranslation();

	const onClickHandler = () => {
		setCurrentLang(currentLang === "ja" ? "en" : "ja");
	};

	React.useEffect(() => {
		i18n.changeLanguage(currentLang);
	}, [currentLang]);

	return (
		<div className={css.container} onClick={onClickHandler}>
			{currentLang === "ja" ? <LangJpSVG /> : <LangEnSVG />}
		</div>
	);
};

export default LanguageButton;
