import React from 'react'
import { gsap, TweenMax, TimelineMax } from "gsap"
import { CustomEase } from "gsap/CustomEase"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import browser from 'browser-detect'
import { useRecoilState } from 'recoil'
import { scrollState } from '@status'


gsap.registerPlugin(CustomEase)
gsap.registerPlugin(ScrollToPlugin)
let customEase = CustomEase.create("custom", "M0,0 C0.356,-0.002 0.298,0.608 0.4,0.8 0.506,1 0.764,1 1,1")

// Hook
function SmoothScroll(targetId, offset = 100)
{
    const [_scrollState, setScrollState] = useRecoilState(scrollState)
    let spOffset = 0
    const { mobile }  = browser()

    if( mobile ){
        spOffset = 64
    }

    if(!targetId) return

    let t = document.getElementById(targetId.replace( /#/g , '' ))

    // console.log(targetId,t.offsetTop)
    if( t ){
        const y = t.offsetTop - offset + spOffset
        TweenMax.to('.container', 0.6,{
            scrollTo: y,
            ease: customEase,
            onComplete: () => {
                setScrollState({
                    ..._scrollState,
                    currentScrollRaito: 1.5
                })
            }
        })
        let p = { value: 0 }
        TweenMax.to(p, 3.0,{
            value: 1.5,
            ease: customEase,
            onUpdate: () => {
                setScrollState({
                    ..._scrollState,
                    currentScrollRaito: p.value
                })
            }
        })
    }
}

export { SmoothScroll }