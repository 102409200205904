// import {useMediaQuery} from "react-responsive"
import LanguageButton from "@components/LanguageButton";
import Link from "@components/Link";
import HeaderSp from "@components/blocks/global/HeaderSp";
import ModalMenu from "@components/blocks/global/ModalMenu";
// import { Pc, Sp } from "@hooks/useMediaQuery"
import { useEventListener } from "@hooks/useEventListener";
import { useMenuLink } from "@hooks/useURL";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

import * as css from "@css/components/blocks/global/Header_v2.module.styl";
import LogoSvg from "@images/Global__logo__miraicomachi.svg";
// import BgSvg from "@images/GlobalHeader__img__bg.svg"

export default function GlobalHeader() {
	const menuLinks = useMenuLink();
	const bgRef = React.useRef();

	const q = useStaticQuery(graphql`
        query {
            bg : file(relativePath: { eq: "GlobalHeader__img__bg.svg" }) {
                publicURL
            }
        }
    `);

	function calculate() {
		if (!bgRef.current) {
			return;
		}
		const deviceWidth = 1280;
		const viewportWidth = window.innerWidth;
		const currentRatio = deviceWidth / viewportWidth;
		let deg = -2;
		if (viewportWidth < 860) {
			deg = -0.7;
		}
		const angle = currentRatio * deg;
		// document.querySelector('.rotating-bar').style.transform = `rotate(${angle}deg)`;
		bgRef.current.style.transform = `rotate(${angle}deg) translateY(-50%) translate3d(0,0,0)`;
	}

	useEventListener("resize", calculate);

	React.useEffect(() => {
		calculate();
	});

	return (
		<React.Fragment>
			<div className={css.pc}>
				<header className={css.container}>
					{/*<div className={css.bg} style={{backgroundImage: `url(${q.bg.publicURL})`}}/>*/}
					<div className={css.inner}>
						<div className={css.nav_left}>
							<div className={css.list}>
								<div className={css.item_logo}>
									<Link to="/">
										<LogoSvg />
									</Link>
								</div>
							</div>
						</div>
						<nav className={css.menu}>
							<ul>
								{menuLinks.map(({ label, to, target, child, blank }, i) => {
									if (child) {
										return (
											<li key={i} className={css.item}>
												<span>{label}</span>
												<ul>
													{child.map(({ label, to, target }, i) => {
														return (
															<li key={i}>
																<Link to={to} scrollTarget={target}>
																	{label}
																</Link>
															</li>
														);
													})}
												</ul>
											</li>
										);
										// biome-ignore lint/style/noUselessElse: <explanation>
									} else {
										return (
											<li className={css.item} key={i}>
												<Link
													to={to}
													scrollTarget={target}
													target={blank ? "_blank" : null}
												>
													{label}
												</Link>
											</li>
										);
									}
								})}
								<li
									className={`${css.item_lange} ${css.nohover}`}
									key={"lange"}
								>
									<LanguageButton />
								</li>
							</ul>
						</nav>
					</div>
				</header>
			</div>
			<div className={css.sp}>
				<ModalMenu />
			</div>
			<div className={css.sp2}>
				<HeaderSp />
			</div>
			<div className={css.bg} ref={bgRef} />
		</React.Fragment>
	);
}
