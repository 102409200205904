import browser from "browser-detect";
import { gsap } from "gsap";
import React from "react";

import LoadingAnimation from "@components/LoadingAnimation";
import * as css from "@css/components/Loading.module.styl";
import { useEventLock } from "@hooks/useEventLock";
import LogoSvg from "@images/Global__logo__miraicomachi.svg";
import { isWebGlState, loadState, transitionCurrentState } from "@status";
import { useRecoilState } from "recoil";

export default function Loading() {
	const eventLock = useEventLock();
	const [_loadState, setLoadState] = useRecoilState(loadState);
	const [_transitionCurrentState, setTransitionCurrentState] = useRecoilState(
		transitionCurrentState,
	);
	const [_isWebGlState, setIsWebGlState] = useRecoilState(isWebGlState);

	const { isLoaded, isLoadedModelData } = _loadState;
	const containerRef = React.useRef();
	const logoRef = React.useRef();
	// const transition = useTransition({isStayEnd: true})
	// const background = { background: !appStore.isWebGl ? "#FF7400" : "none" }
	const background = { background: "#DF4661" };

	const browsed = React.useRef(browser());
	let isWebGl = true;
	if (browsed.current.name === "ie" || !isWebGlState) {
		isWebGl = false;
	}

	const hideAnimation = () => {
		gsap.to(containerRef.current, {
			duration: 0.3,
			// delay: 2.4,
			opacity: 0,
			display: "none",
			onComplete: () => {
				setLoadState({
					..._loadState,
					isLoadedAnimated: true,
				});
				eventLock.off();
				if (window.locomotiveScroll) {
					window.locomotiveScroll.update();
				}
			},
		});
	};

	const showAnimation = (callback) => {
		gsap.to(containerRef.current, {
			duration: 0.3,
			// delay: 1.2,
			opacity: 1.0,
			display: "block",
			onComplete: () => {
				// appStore.loadedAnimated();
				setLoadState({
					..._loadState,
					isLoadedAnimated: true,
				});
				eventLock.on();
				if (window.locomotiveScroll) {
					window.locomotiveScroll.scrollTo(0, 0, 0);
				}
				callback && callback();
			},
		});
	};

	//loading
	React.useEffect(() => {
		if (isWebGl) {
			// if( isLoaded && isLoadedModelData){
			if (isLoaded) {
				hideAnimation();
			}
		} else {
			setTimeout(() => {
				hideAnimation();
			}, 1000);
		}
	}, [isLoaded, isLoadedModelData]);

	//transition
	React.useEffect(() => {
		if (isWebGl) {
			switch (_transitionCurrentState) {
				case "middle":
					showAnimation();
					break;
				case "end":
					hideAnimation();
					break;
			}
		} else {
			switch (_transitionCurrentState) {
				case "start":
					showAnimation(() => {
						// appStore.transitionCurrent = 'end'
					});
					break;
				case "end":
					hideAnimation();
					break;
			}
		}
	}, [_transitionCurrentState]);

	// React.useEffect(()=>{
	//     if( isLoaded ){
	//         gsap.to(containerRef.current, 0.3,{
	//             delay: 0.3,
	//             opacity: 0,
	//             display: 'none',
	//             onComplete: () => {
	//                 appStore.loadedAnimated()
	//             }
	//         })
	//     }
	// },[isLoaded])

	// style={{ display: isLoaded ? 'none' : 'block'}}
	return (
		<React.Fragment>
			<div ref={containerRef} className={css.container} style={background}>
				<div className={css.content}>
					<div className={css.block}>
						<div ref={logoRef} className={css.logo}>
							<LogoSvg />
						</div>
						<LoadingAnimation />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
